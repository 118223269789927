import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Tooltip from "@material-ui/core/Tooltip";
import { FaFirefoxBrowser } from "react-icons/fa";
import {
  useUpdateUserAgentsMutation,
  useGetUserAgentsItemQuery,
  useLazyGetUserAgentsCurrentQuery,
} from "redux/api/UserAgents/UserAgentsApi";

export default function Create(props) {
  const { show, onHide, id } = props;
  const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { data } = useGetUserAgentsItemQuery(id);
  const [
    getUserAgentsCurrent,
    { data: currentAgent, isLoading: currentAgentLoading },
  ] = useLazyGetUserAgentsCurrentQuery();

  const initialValues = {
    value: "",
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (data) {
      const { id, value } = data;
      setValues({ id, value });
    }
  }, [data]);

  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };

  const [
    updateUserAgents,
    { isLoading, isSuccess },
  ] = useUpdateUserAgentsMutation();
  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    value: Yup.string().required("Məlumat daxil etməyiniz tələb olunur."),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };
  const getAgentsCurrent = async () => {
    try {
      const dataCurrent = await getUserAgentsCurrent();

      if (dataCurrent) {
        setValues({
          ...values,
          value: dataCurrent?.data,
        });
      }
    } catch (error) {
      console.error("Error in getAgentsCurrent:", error);
    }
  };
  const onPostHumanResource = (values) => {
    const humanResource = {
      id,
      value: values.value,
    };

    updateUserAgents(humanResource);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { setValues, resetForm, values } = formik;

  useEffect(() => {
    setIsError("");
  }, [show]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Düzəliş et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-11 col-md-11 col-lg-11">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="value"
                    name="value"
                    label="Value"
                    placeholder="Value"
                    className="w-100"
                    error={getInputClasses("value")}
                    {...formik.getFieldProps("value")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.value && formik.errors.value ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.value}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-1 col-md-1 col-lg-1">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <Tooltip
                    title="Cari brauzerin istifadəçi agentini götür"
                    placement="top"
                    sx={{ fontSize: "24px" }}
                  >
                    <Button
                      onClick={getAgentsCurrent}
                      type="button"
                      className="current_agent"
                    >
                      {currentAgentLoading ? (
                        <span className="mr-3 spinner spinner-white"></span>
                      ) : (
                        <FaFirefoxBrowser />
                      )}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Düzəliş et
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
