import React from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "../../../../app/modules/UI/Datetimepicker";
import TextField from "@material-ui/core/TextField/index";

export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    setPage,
  } = props;

  const onResetFilter = () => {
    setFilterInputs({
      expression: "",
      description: "",
      FromDate: "",
      ToDate: "",
    });
  };

  function handleChangeStartDate(e) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      FromDate: e.target.value,
    });
  }
  function handleChangeEndDate(e) {
    setPage(0);

    setFilterInputs({
      ...filterInputs,
      ToDate: e.target.value,
    });
  }

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">Axtarış Açar Sözlər filtri</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="expression"
                  name="expression"
                  label="İfadə"
                  placeholder="İfadə"
                  className="w-100"
                  value={filterInputs.firstName}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="description"
                  name="description"
                  label="Təsviri"
                  placeholder="Təsviri"
                  className="w-100"
                  value={filterInputs.lastName}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  label="Başlanğıc tarix"
                  onChange={(e) => handleChangeStartDate(e)}
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  label="Son tarix"
                  onChange={(e) => handleChangeEndDate(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            onResetFilter();
          }}
        >
          Sıfırla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
