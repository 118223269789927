import {createSlice} from "@reduxjs/toolkit"




const initialState= {
    sources: [],
  
}

export const sourcesSlice = createSlice({
    initialState,
    name: "sourcesSlice",
    reducers: {
        setSources: (state,action) => {
            state.sources =action.payload
        },
      
    }
})

export default sourcesSlice.reducer;
export const {setKeyword} = sourcesSlice.actions