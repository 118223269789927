import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetScrapeTemplatesQuery } from "redux/api/ScrapeTemplates/templatesApi";
import { useCreateSourcesPathMutation } from "redux/api/NewsSourcesPath/newsSourcesPathApi";
import ReactSelect from "app/modules/UI/ReactSelect";
import RenderIf from "app/modules/UI/RenderIf";

export default function Create(props) {
  const { show, onHide, refetch ,id} = props;
  const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [defaultState, setDefaultState] = useState(false);
  const [langOption, setLangOption] = React.useState(null);
  const [sourceOption, setSourceOption] = React.useState(null);
  const [sourceOptions, setSourceOptions] = React.useState(null);


  const { data } = useGetScrapeTemplatesQuery({
 skip: "",
  take: "",
  field: "id",
  orderBy: false,
  name: {  NewsSourceId:id,}
});

  const initialValues = {
    newsSourceScrapeTemplateId: "",
    url: "",
    dateCulture: "",
  };

  const langOptions = [
    { value: "AZ", label: "AZ" },
    { value: "EN", label: "EN" },
    { value: "RU", label: "RU" },
  ];
  useEffect(() => {
    if (data) {
      const newSelectData = data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSourceOptions([ ...newSelectData]);
    }
  }, [data]);

 
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  
 
  const [
    createSourcesPath,
    { isLoading, error, isSuccess },
  ] = useCreateSourcesPathMutation();
  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    newsSourceScrapeTemplateId: Yup.string().required("Şablon daxil etməyiniz tələb olunur."),
    url: Yup.string().required("Url daxil etməyiniz tələb olunur."),
    dateCulture: Yup.string().required("Sayt dilini daxil etməyiniz tələb olunur."),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostHumanResource = (values) => {
    const humanResource = {
      newsSourceId:+id,
      newsSourceScrapeTemplateId: values.newsSourceScrapeTemplateId,
      url: values.url,
      dateCulture: values.dateCulture,
    };

    createSourcesPath(humanResource);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { resetForm, setFieldValue } = formik;
  function handleChangeLang(value) {
    setLangOption(value);
    setFieldValue("dateCulture",value?.value)
  }
  function handleChangeScrapeTemplate(value) {
    setSourceOption(value);
    setFieldValue("newsSourceScrapeTemplateId",value?.value)
  }

  useEffect(() => {
    setIsError("");
  }, [show]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Əlavə et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactSelect
                    suggestions={sourceOptions}
                    handleChangeSingle={(value) => handleChangeScrapeTemplate(value)}
                    single={sourceOption}
                    label="Scrape Şablon"
                    placeholder="Scrape Şablon"
                    inputId="roleId"
                  />
                </div>
                <RenderIf condition={formik.touched.newsSourceScrapeTemplateId && formik.errors.newsSourceScrapeTemplateId}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.newsSourceScrapeTemplateId}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="address"
                    label="Url"
                    placeholder="Url"
                    className="w-100"
                    error={getInputClasses("url")}
                    {...formik.getFieldProps("url")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.url && formik.errors.url}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.url}</div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={langOptions}
                    handleChangeSingle={(value) => handleChangeLang(value)}
                    single={langOption}
                    label="Saytın dili"
                    placeholder="Saytın dili"
                    inputId="roleId"
                  />
                </div>
                <RenderIf condition={formik.touched.dateCulture && formik.errors.dateCulture}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.dateCulture}</div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Əlavə et
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
