
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200,
  },
}));

export default function DateAndTimePickers({label,onChange,value}) {
  const classes = useStyles();

  return (
    <TextField
      id="datetime-local"
      label={label}
      onChange={onChange}
      type="datetime-local"
      value={value}
      // defaultValue="2017-05-24T10:30"
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}
