import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ["Categories"],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `categories?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.name ? "&Name=" + name?.name : ""
          }${name?.url ? "&Url=" + name?.url : ""}${
            name?.FromDate ? "&FromDate=" + name?.FromDate : ""
          }${
            name?.ToDate ? "&ToDate=" + name?.ToDate : ""
          }${
            name?.parentCategoryId ? "&ParentCategoryId=" + name?.parentCategoryId : ""
          }`,
        };
      },
      providesTags: ["Categories"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getCategoriesItem: builder.query({
      query(id) {
        return {
          url: `categories/${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createCategories: builder.mutation({
      query(form) {
        return {
          url: "categories",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Categories"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateCategories: builder.mutation({
      query(data) {
        return {
          url: "categories",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["Categories"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteCategories: builder.mutation({
      query(id) {
        return {
          url: `categories/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["Categories"],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoriesItemQuery,
 useUpdateCategoriesMutation,
  useCreateCategoriesMutation,
 useDeleteCategoriesMutation,
} = categoriesApi;
