import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import ReactMultiSelect from "../../UI/ReactMultiSelect";
import { useFormik } from "formik";
import MailPart from "../components/MailPart";
import HourPart from "../components/HourPartEdit";
import CategoryPart from "../components/CategoryPart";
import { toast } from "react-toastify";
import { useGetSourcesQuery } from "redux/api/NewsSources/newsSourcesApi";
import * as Yup from "yup";
import {
  useUpdateEmployeesMutation,
  useGetEmployeeQuery,
} from "redux/api/Employees/employeesApi";
import {
  useGetCategoriesQuery,
  useCreateCategoriesMutation,
} from "redux/api/Categories/categoriesApi";

import { values } from "lodash";

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const [disabled, setDisabled] = useState(false);
  const [searchKeywords, setArray] = React.useState([]);
  const [searchKeywordsOption, setSearchKeywordsOption] = React.useState(null);
  const [searchKeywordsOptions, setSearchKeywordsOptions] = React.useState(
    null
  );
  const [categoryOption, setCategoryOption] = React.useState(null);
  const [categoryOptions, setCategoryOptions] = React.useState(null);
  const [categoryValue, setCategoryValue] = useState();
  const [value, setValue] = useState(1);
  const initialValues = {
    name: "",
    username: "",
    password: "",
    clientNewsSources: [],
    clientCategories: [
      {
        categoryId: "",
        order: "",
      },
    ],
    clientMails: [
      {
        email: "",
      },
    ],
  };

  function changeRadioValue(event) {
    setValue(event.target.value);
  }
  const [pvalues, setPValues] = useState({
    password: "",
    showPassword: false,
  });

  const { data } = useGetCategoriesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });

  const { data: sources } = useGetSourcesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
    status: true,
  });

  useEffect(() => {
    if (sources) {
      const newSelectData = sources?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSearchKeywordsOptions([...newSelectData]);
    }
  }, [sources]);
  useEffect(() => {
    if (data) {
      const newSelectData = data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCategoryOptions([...newSelectData]);
    }
  }, [data]);

  const EmployeeData = useGetEmployeeQuery(id);
  const EmployeeDatas = EmployeeData?.data;
  const filePath = EmployeeDatas?.fileUrl;
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState(true);
  const [imageSrc, setImageSrc] = useState(filePath);
  useEffect(() => {
    setImageSrc(filePath);
  }, [filePath]);

  useEffect(() => {
    if (categoryOptions && EmployeeDatas?.clientCategories) {
      setCategoryValue(
        EmployeeDatas?.clientCategories?.map((el) =>
          categoryOptions?.find((mo) => mo?.value == el?.categoryId)
        )
      );
    }
  }, [EmployeeDatas, categoryOptions]);

  const [keywordChosen, setKeywordChosen] = React.useState([]);

  useEffect(() => {
    setValues({
      ...values,
      clientNewsSources: EmployeeData?.data?.clientNewsSources,
    });
    if (EmployeeData?.data?.clientNewsSources && searchKeywordsOptions) {
      EmployeeData?.data?.clientNewsSources?.map((item, index) => {
        keywordChosen.push(
          searchKeywordsOptions?.find(
            (option) => option.value == item.newsSourceId
          )
        );
        return index;
      });

      setArray(EmployeeData?.data?.clientNewsSources);

      setSearchKeywordsOption((prev) => keywordChosen);
    }
  }, [EmployeeData?.data, searchKeywordsOptions]);

  useEffect(() => {
    if (EmployeeData?.data) {
      const {
        name,
        username,
        clientCategories,
        clientMails,
        clientMailHours,
      } = EmployeeData?.data;
      setValues({
        id,
        name,
        username,
        clientCategories,
        clientMails,
        clientMailHours,
      });
    }
  }, [EmployeeData?.data]);

  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(e.target.value);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);

    setPreview(true);
  };
  const onClick = (e) => {
    setPreview(false);
    setImageSrc("");
    setFile("");
  };

  const handleClickShowPassword = (key) => {
    setPValues({ ...pvalues, [key]: !pvalues[key] });
  };
  function handleChangeInterest(c) {
    setSearchKeywordsOption(c);
    const mockArray = [];
    c.forEach((element) => {
      const categoryObject = { newsSourceId: element.value };
      mockArray.push(categoryObject);
    });
    setArray(mockArray);
    setValues({ ...values, clientNewsSources: mockArray });
  }
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateEmployees,
    { isLoading, error, isSuccess, isError: EditError },
  ] = useUpdateEmployeesMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    name: Yup.string().required("Ad daxil etməyiniz tələb olunur."),
    username: Yup.string().required(
      "İstifadəçi adı daxil etməyiniz tələb olunur"
    ),
    clientMails: Yup.array()
      .required("Email boş ola bilməz")
      .of(
        Yup.object().shape({
          email: Yup.string().required("Email daxil etməyiniz tələb olunur"),
        })
      ),

    clientCategories: Yup.array()
      .required("Kateqoriya və order boş ola bilməz")
      .of(
        Yup.object().shape({
          categoryId: Yup.string().required(
            "Kateqoriya daxil etməyiniz tələb olunur"
          ),
          order: Yup.string().required("Sıra daxil edin"),
        })
      ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: EditSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      updateEmployees({ ...values, id });
    },
  });
  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const { resetForm, values, setFieldValue, setValues, errors } = formik;
  const addProtocolParticipants = () => {
    const clientMails = [...values.clientMails, { email: "" }];

    setValues({ ...values, clientMails });
  };

  const handleRemoveParticipantItem = (index) => {
    let clientMails = [...values.clientMails];
    clientMails = clientMails.filter((_, i) => i !== index);
    setValues({ ...values, clientMails });
  };

  function handleChangeCategory(value, index) {
    const newCategoryValues = [...categoryValue];
    newCategoryValues[index] = value;
    setCategoryValue(newCategoryValues);
    formik.setFieldValue(`clientCategories[${index}].categoryId`, value.value);
  }
  const showSecond = false;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  function handleChangeHour(value, index) {
    const newHourValues = [...values.clientMailHours];
    newHourValues[index] = value.format(str);
    formik.setFieldValue(
      `clientMailHours[${index}].hour`,
      value && value.format(str)
    );
  }
  const addCategory = () => {
    const clientCategories = [
      ...values.clientCategories,
      { categoryId: "", order: "" },
    ];

    setValues({ ...values, clientCategories });
  };

  const handleRemoveCategory = (index) => {
    let clientCategories = [...values.clientCategories];
    clientCategories = clientCategories.filter((_, i) => i !== index);
    setValues({ ...values, clientCategories });

 
    setCategoryValue(categoryValue.filter((_, i) => i !== index))
  };
 

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <form onSubmit={formik.handleSubmit} ref={form}>
        <Modal.Header closeButton>
          <Modal.Title id="edit">Düzəliş et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    name="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                  />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="username"
                    label="İstifadəçi adı"
                    placeholder="İstifadəçi adı"
                    className="w-100"
                    error={getInputClasses("username")}
                    {...formik.getFieldProps("username")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lastName}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mt-5 row">
              <div className="col-6">
                <h3>Kategoriya və Sıra :</h3>
              </div>
              <div className="col-6">
                <h3> Email ünvanları:</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <CategoryPart
                  getFieldProps={formik.getFieldProps}
                  getInputCheck={getInputClasses}
                  addProtocolParticipants={addCategory}
                  categoryOptions={categoryOptions}
                  handleChangeCategory={handleChangeCategory}
                  values={formik.values}
                  singleValue={categoryValue}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleRemoveParticipantItem={handleRemoveCategory}
                />
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <MailPart
                  getFieldProps={formik.getFieldProps}
                  getInputCheck={getInputClasses}
                  addProtocolParticipants={addProtocolParticipants}
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleRemoveParticipantItem={handleRemoveParticipantItem}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              resetForm();
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Düzəliş et
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
