import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const userAgentsApi = createApi({
  reducerPath: "userAgentsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ["UserAgents"],
  endpoints: (builder) => ({
    getUserAgents: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;

        return {
          url: `user-agents?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.value ? "&value=" + name?.value : ""
          }${name?.searchTerm ? "&searchTerm=" + name?.searchTerm : ""}${
            name?.FromDate ? "&FromDate=" + name?.FromDate : ""
          }${name?.ToDate ? "&ToDate=" + name?.ToDate : ""}`,
        };
      },
      providesTags: ["UserAgents"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getUserAgentsItem: builder.query({
      query(id) {
        return {
          url: `user-agents/${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getUserAgentsCurrent: builder.query({
      query() {
        return {
          url: `user-agents/current`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createUserAgents: builder.mutation({
      query(form) {
        return {
          url: "user-agents",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["UserAgents"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateUserAgents: builder.mutation({
      query(data) {
        return {
          url: "user-agents",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["UserAgents"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    changeStatus: builder.mutation({
      query(data) {
        return {
          url: `user-agents/${data?.id}/status`,
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["UserAgents"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteUserAgents: builder.mutation({
      query(id) {
        return {
          url: `user-agents/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["UserAgents"],
    }),
  }),
});

export const {
  useLazyGetUserAgentsCurrentQuery,
  useChangeStatusMutation,
  useGetUserAgentsQuery,
  useGetUserAgentsItemQuery,
  useUpdateUserAgentsMutation,
  useCreateUserAgentsMutation,
  useDeleteUserAgentsMutation,
} = userAgentsApi;
