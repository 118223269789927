import React from "react";

const TableCheck = ({ rows }) => {
  console.log(rows);
  return (
    <div>
      <div class="container news_source_check_table">
        <div class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Başlıq</th>
                <th>Url</th>
                <th>Tarix</th>
              </tr>
            </thead>
            <tbody>
              {rows?.map((row, index) => {
                return (
                  <tr>
                    <td>{row?.title}</td>
                    <td>
                     <a href={row?.url} target="_blank">{row?.url}</a>
                      </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {row?.newsDate?.split("T")[0]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableCheck;
