import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import PlusIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: 100,
  },
}));

const ParticipantInformation = ({
  getInputCheck,
  getFieldProps,
  values,
  touched,
  handleEnter,
  handleChangeHour,
  errors,
  addHour,
  handleRemoveHour,
}) => {
  const classes = useStyles();
  return (
    <div>
      {values?.clientMailHours?.map((participant, index, key) => {
        const clientMailHoursError =
          (errors.clientMailHours?.length && errors.clientMailHours[index]) ||
          {};
        const clientMailHoursTouch =
          (touched.clientMailHours?.length && touched.clientMailHours[index]) ||
          {};
        const outputArray = values?.clientMailHours.map((item) => item.hour);

        return (
          <div className="row mt-2">
            <div className="col col-11 client_email_hour_input">
              {" "}
              <TimePicker
                placeholder="Email göndəriş saatı seçin... "
                showSecond={false}
                onChange={(value) => handleChangeHour(value, index)}
              />
              <AccessTimeIcon />
            </div>

            <div className="col col-1">
              <span
                aria-label="Delete"
                className="btn  btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                onClick={() => {
                  handleRemoveHour(index);
                }}
              >
                <DeleteIcon />
              </span>
            </div>

            {clientMailHoursTouch.hour && clientMailHoursError.hour ? (
              <div className="mt-2 ml-2">
                <div className="text-danger">
                  {clientMailHoursError["hour"]
                    ? clientMailHoursError["hour"]
                    : clientMailHoursError["hour"]}
                </div>
              </div>
            ) : null}
          </div>
        );
      })}

      <div className="col col-6 d-flex align-items-end mt-4 p-0">
        <Button onClick={addHour} className={`px-3`} type="button">
          <PlusIcon /> Saat əlavə et
        </Button>
      </div>
    </div>
  );
};

export default ParticipantInformation;
