import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ReactMultiSelect from "../../UI/ReactMultiSelect";
import MailPart from "../components/MailPart";
import HourPart from "../components/HourPart";
import CategoryPart from "../components/CategoryPart";
import { toast, ToastContainer } from "react-toastify";
import { useCreateEmployeesMutation } from "redux/api/Employees/employeesApi";
import { useGetSourcesQuery } from "redux/api/NewsSources/newsSourcesApi";
import {
  useGetCategoriesQuery,
  useCreateCategoriesMutation,
} from "redux/api/Categories/categoriesApi";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputLabel from "@material-ui/core/InputLabel";

export default function Create(props) {
  const { show, onHide, refetch } = props;
  // const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [defaultState, setDefaultState] = useState(false);
  const [searchKeywords, setArray] = React.useState([]);
  const [searchKeywordsOption, setSearchKeywordsOption] = React.useState(null);
  const [searchKeywordsOptions, setSearchKeywordsOptions] = React.useState(
    null
  );
  const [categoryOption, setCategoryOption] = React.useState(null);
  const [categoryOptions, setCategoryOptions] = React.useState(null);




  const initialValues = {
    name: "",
    username: "",
    password: "",
    clientCategories: [
      {
        categoryId: "",
        order: "",
      },
    ],
    clientMails: [
      {
        email: "",
      },
    ],
   
  };

  const { data } = useGetCategoriesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });

  const { data: sources } = useGetSourcesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
    status: true,
  });

  useEffect(() => {
    if (sources) {
      const newSelectData = sources?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSearchKeywordsOptions([...newSelectData]);
    }
  }, [sources]);
  useEffect(() => {
    if (data) {
      const newSelectData = data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCategoryOptions([...newSelectData]);
    }
  }, [data]);
  const form = useRef(null);
  const [phone, setPhone] = React.useState("");

  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };



  const [
    createEmployees,
    { isLoading, error, isSuccess, isError },
  ] = useCreateEmployeesMutation();

  const CreateSchema = Yup.object().shape({

    name: Yup.string().required("Ad daxil etməyiniz tələb olunur."),
    password: Yup.string().required("Parol daxil etməyiniz tələb olunur"),
    username: Yup.string().required(
      "İstifadəçi adı daxil etməyiniz tələb olunur"
    ),
 
    clientMails: Yup.array()
      .required("Email boş ola bilməz")
      .of(
        Yup.object().shape({
          email: Yup.string().required("Email daxil etməyiniz tələb olunur"),
        })
      ),
 
    clientCategories: Yup.array()
      .required("Kateqoriya və order boş ola bilməz")
      .of(
        Yup.object().shape({
          categoryId: Yup.string().required(
            "Kateqoriya daxil etməyiniz tələb olunur"
          ),
          order: Yup.string().required("Sıra daxil edin"),
        })
      ),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostHumanResource = (values, resetForm, setSubmitting) => {
    createEmployees(values);
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }

  }, [isLoading]);

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { setValues, resetForm, values } = formik;

  const addProtocolParticipants = () => {
    const clientMails = [...values.clientMails, { email: "" }];

    setValues({ ...values, clientMails });
  };

  const handleRemoveParticipantItem = (index) => {
    let clientMails = [...values.clientMails];
    clientMails = clientMails.filter((_, i) => i !== index);
    setValues({ ...values, clientMails });
  };

  
  function handleChangeCategory(value, index) {
    formik.setFieldValue(`clientCategories[${index}].categoryId`, value.value);
  }
  const showSecond = false;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  function handleChangeHour(value, index) {

    formik.setFieldValue(
      `clientMailHours[${index}].hour`,
      value && value.format(str)
    );
  }
  const addCategory = () => {
    const clientCategories = [
      ...values.clientCategories,
      { categoryId: "", order: "" },
    ];

    setValues({ ...values, clientCategories });
  };

  const handleRemoveCategory = (index) => {
    let clientCategories = [...values.clientCategories];
    clientCategories = clientCategories.filter((_, i) => i !== index);
    setValues({ ...values, clientCategories });
  };

  useEffect(() => {
    // setIsError("");
    setPhone("");
  }, [show]);


  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit} ref={form}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Əlavə et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    name="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                  />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.name}
                    </div>
                  </div>
                ) : null}
              </div>
          

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="username"
                    label="İstifadəçi adı"
                    placeholder="İstifadəçi adı"
                    className="w-100"
                    error={getInputClasses("username")}
                    {...formik.getFieldProps("username")}
                    onKeyDown={handleEnter}
                  />
                </div>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      
                      {formik.errors.lastName}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="password"
                    className="w-100"
                    type={values.showPassword ? "text" : "password"}
                    label="Şifrə"
                    error={getInputClasses("password")}
                    {...formik.getFieldProps("password")}
                    onKeyDown={handleEnter}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showPassword")
                            }
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>


              
            </div>
            <div className="mt-5 row">
            <div className="col-6">
                <h3>Kategoriya və Sıra :</h3>
              </div>
              <div className="col-6">
                <h3> Email ünvanları:</h3>
              </div>
            </div>
            <div className="row">
            <div className="col-12 col-md-12 col-lg-6">
                <CategoryPart
                  getFieldProps={formik.getFieldProps}
                  getInputCheck={getInputClasses}
                  addProtocolParticipants={addCategory}
                  categoryOptions={categoryOptions}
                  handleChangeCategory={handleChangeCategory}
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleRemoveParticipantItem={handleRemoveCategory}
                />
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <MailPart
                  getFieldProps={formik.getFieldProps}
                  getInputCheck={getInputClasses}
                  addProtocolParticipants={addProtocolParticipants}
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleRemoveParticipantItem={handleRemoveParticipantItem}
                />
              </div>
            </div>
      

     
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Əlavə et
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
