import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const videoNewsApi = createApi({
  reducerPath: "VideoNewsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ["VideoNews"],
  endpoints: (builder) => ({
    getVideoNews: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;

        return {
          url: `video-news?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.VideoUrl ? "&VideoUrl=" + name?.VideoUrl : ""
          }${name?.Title ? "&Title=" + name?.Title : ""}${name?.TvChannelId ? "&TvChannelId=" + name?.TvChannelId : ""}`,
        };
      },
      providesTags: ["VideoNews"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getVideoNewsItem: builder.query({
      query(id) {
        return {
          url: `video-news/${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    createVideoNews: builder.mutation({
      query(form) {
        return {
          url: "video-news",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["VideoNews"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateVideoNews: builder.mutation({
      query(data) {
        return {
          url: "video-news",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["VideoNews"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    changeStatus: builder.mutation({
      query(data) {
        return {
          url: `video-news/${data?.id}/status`,
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["VideoNews"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteVideoNews: builder.mutation({
      query(id) {
        return {
          url: `video-news/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["VideoNews"],
    }),
  }),
});

export const {
  useChangeStatusMutation,
  useGetVideoNewsQuery,
  useGetVideoNewsItemQuery,
  useUpdateVideoNewsMutation,
  useCreateVideoNewsMutation,
  useDeleteVideoNewsMutation,
} = videoNewsApi;
