import React, { useState, useEffect, useRef } from "react";
import DataTables from "./components/EmployeeTables";
import ChangePassword from "./modals/ChangePassword";
import { useHistory, useLocation } from "react-router-dom";
import View from "./modals/View";
import Create from "./modals/Create";
import Delete from "./modals/Remove";
import Filter from "./modals/Filter";
import Edit from "./modals/Edit";
import Switch from "@material-ui/core/Switch/index";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import { ToastContainer } from "react-toastify";
import { updateObject } from "../../../Helpers/updateObject";
import {
  useGetEmployeesQuery,
  useUpdateEmployeStatusMutation,
} from "redux/api/Employees/employeesApi";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import RenderIf from "app/modules/UI/RenderIf";
import { useSelector } from "react-redux";

export function Employees() {
  const history = useHistory();
  const location = useLocation();

  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const { user } = useSelector((state) => state.user);

  const handleChange = () => {
    setShowFilter(!showFilter);
  };
  const handleClick = () => {
    setOpen(true);
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowView(open);
  };

  function doc_keyUp(e) {
    if (e.ctrlKey && e.key === "x") {
      setShowCreate(true);
    }
  }
  document.addEventListener("keyup", doc_keyUp, false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortField, setSortField] = useState("lastUpdatedDate");
  const [orderBy, setOrderBy] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [filter, setFilter] = useState(false);

  const params = new URLSearchParams(location.search);
  const initialFilterInputs = {
    Name: params.get("Name") || "",
    Username: params.get("Username") || "",
    url: params.get("url") || "",
    deleted: params.get("status") || null,
  };
  const [filterInputs, setFilterInputs] = useState(initialFilterInputs);
  const { data, refetch, isLoading } = useGetEmployeesQuery({
    skip: page * rowsPerPage,
    take: rowsPerPage,
    field: "id",
    orderBy: false,
    name: filterInputs,
  });

  const [updateEmployeStatus, { isSuccess }] = useUpdateEmployeStatusMutation();

  const onUpdateUserStatus = (id, statusType) => {
    let status = !statusType;
    const asset = {
      id,
      status,
    };

    updateEmployeStatus(asset);
  };
  useEffect(() => {
    const pageFromUrl = parseInt(params.get("page"), 10) || 0;
    const rowsPerPageFromUrl = parseInt(params.get("rowsPerPage"), 10) || 10;
    setPage(pageFromUrl);
    setRowsPerPage(rowsPerPageFromUrl);
  }, [location.search]);

  const updateUrlParams = (
    newFilterInputs,
    newPage = page,
    newRowsPerPage = rowsPerPage
  ) => {
    const params = new URLSearchParams({
      ...newFilterInputs,
      page: newPage,
      rowsPerPage: newRowsPerPage,
    });
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
    updateUrlParams(filterInputs, newPage);
  }

  function handleChangeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    updateUrlParams(filterInputs, 0, newRowsPerPage);
  }

  const filterInputsUpdate = (e) => {
    setPage(0);
    const updatedFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });
    setFilterInputs(updatedFiltersInput);
    updateUrlParams(updatedFiltersInput, 0);
  };
  const filterStatusesUpdate = (key, value) => {
    const updatedFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });
    setFilterInputs(updatedFiltersInput);
    setFilter(true);
    updateUrlParams(updatedFiltersInput, 0);
  };

  return (
    <div className="row bg-white rounded d-flex justify-content-center h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">Müştərilər</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label={showFilter ? "Filtri gizlət" : "Filtri göstər"}
          className="ml-auto mb-0"
        />
      </div>

      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : data && !isLoading ? (
        <DataTables
          setSortField={setSortField}
          setOrderByTable={setOrderBy}
          sortField={sortField}
          orderBy={orderBy}
          setOpenEdit={setShowEdit}
          setOpenView={toggleDrawer(true)}
          setOpenRemove={setShowRemove}
          setOpenCreate={setShowCreate}
          setOpenChangePassword={setShowChangePassword}
          rows={data.data}
          rowsPerPage={rowsPerPage}
          page={page}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={data.count}
          setEmployeeId={setEmployeeId}
          onUpdateUserStatus={onUpdateUserStatus}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterStatusesUpdate={filterStatusesUpdate}
        />
      ) : (
        ""
      )}

      {showView && (
        <View id={employeeId} open={showView} onClose={toggleDrawer(false)} />
      )}
      {showCreate && (
        <Create
          show={showCreate}
          onHide={setShowCreate}
          refetch={refetch}
          sortField={sortField}
          orderBy={orderBy}
          filterInputs={filterInputs}
        />
      )}
      {showRemove && (
        <Delete
          id={employeeId}
          show={showRemove}
          onHide={setShowRemove}
          refetch={refetch}
          sortField={sortField}
          orderBy={orderBy}
          filterInputs={filterInputs}
        />
      )}
      <RenderIf condition={showChangePassword}>
        <ChangePassword
          show={showChangePassword}
          onHide={setShowChangePassword}
          handleClick={handleClick}
          id={employeeId}
        />
      </RenderIf>
      {showEdit && (
        <Edit
          id={employeeId}
          show={showEdit}
          onHide={setShowEdit}
          sortField={sortField}
          orderBy={orderBy}
          refetch={refetch}
          filterInputs={filterInputs}
        />
      )}

      <Filter
        setPage={setPage}
        show={showFilter}
        onHide={handleChange}
        filterInputsUpdate={filterInputsUpdate}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
      />

      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
