import React from "react";
import { Modal, Button } from "react-bootstrap";
import ReactSelect from "app/modules/UI/ReactSelect";
import DatePicker from "app/modules/UI/Datetimepicker";
import TextField from "@material-ui/core/TextField/index";

export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    setPage
  } = props;
  const [roleOption, setRoleOption] = React.useState({
    value: false,
    label: "Mövcud",
  });
  const onResetFilter = () => {
    setFilterInputs({
      title: "",
      deleted: false,
      FromDate: "",
      ToDate: "",
    });
  };
  const rolesOptions = [
    { value: null, label: "Hamısı" },
    { value: false, label: "Mövcud" },
    { value: true, label: "Silinən" },
  ];
  function handleChangeRole(value) {
    setPage(0);
    setRoleOption(value);
    setFilterInputs({
      deleted: value?.value,
    });
  }

  function handleChangeStartDate(e) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      FromDate: e.target.value,
    });
  }
  function handleChangeEndDate(e) {
    setPage(0);
    setFilterInputs({
      ...filterInputs,
      ToDate: e.target.value,
    });
  }

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">Xəbərlər filtri</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="title"
                  name="title"
                  label="Başlıq"
                  placeholder="Başlıq"
                  className="w-100"
                  value={filterInputs.title}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
        
           
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <ReactSelect
                  suggestions={rolesOptions}
                  handleChangeSingle={(value) => handleChangeRole(value)}
                  single={roleOption}
                  label="Status"
                  placeholder="Status"
                  inputId="roleId"
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.FromDate}
                  label="Başlanğıc tarix"
                  onChange={(e) => handleChangeStartDate(e)}
                />
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePicker
                  value={filterInputs.ToDate}
                  label="Son tarix"
                  onChange={(e) => handleChangeEndDate(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            onResetFilter();
          }}
        >
          Sıfırla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
