import React, { useState, useEffect } from "react";
import DataTables from "./components/TvChannels";
import { useHistory, useLocation } from "react-router-dom";
import View from "./modals/View";
import Create from "./modals/Create";
import Delete from "./modals/Remove";
import Filter from "./modals/Filter";
import Edit from "./modals/Edit";
import Switch from "@material-ui/core/Switch/index";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import { updateObject } from "Helpers/updateObject";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import {
  useGetTvChannelsQuery,
  useChangeStatusMutation,
} from "redux/api/TvChannels/TvChannelsApi";
import { useSelector } from "react-redux";
import RenderIf from "../UI/RenderIf";

export function TvChannels() {
  const history = useHistory();
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = React.useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const { user } = useSelector((state) => state.user);
  const permissions = user?.permissions;
  const handleChange = () => {
    setShowFilter(!showFilter);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowView(open);
  };

  function doc_keyUp(e) {
    if (e.ctrlKey && e.key === "x") {
      setShowCreate(true);
    }
  }
  document.addEventListener("keyup", doc_keyUp, false);
  const location = useLocation();
 
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [proxiesId, setProxiesId] = useState(null);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterInputs, setFilterInputs] = useState({
    name: "",
  });
  const [changeStatus, { isSuccess }] = useChangeStatusMutation();

  const { data, isLoading } = useGetTvChannelsQuery({
    skip: page * rowsPerPage,
    take: rowsPerPage,
    field: "id",
    orderBy: false,
    name: filterInputs,
  });

  const onUpdateUserStatus = (id, statusType) => {
    let status = !statusType;
    const sendData = { id, status };
    changeStatus(sendData);
  };

  function handleChangePage(event, newPage) {
    const newUrl = `?page=${newPage}&rowsPerPage=${rowsPerPage}`;
    history.replace(newUrl);
    setPage(newPage);
  }

  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(params.get("page"), 10) || 0;
    const rowsPerPageFromUrl = parseInt(params.get("rowsPerPage"), 10) || 10;
    setPage(pageFromUrl);
    setRowsPerPage(rowsPerPageFromUrl);
  }, []);

  function handleChangeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    history.push(`?page=0&rowsPerPage=${newRowsPerPage}`);
  }
  const filterInputsUpdate = (e) => {
    setPage(0);
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });
    setFilterInputs(updateFiltersInput);
  };
  const filterStatusesUpdate = (key, value) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });
    setFilterInputs(updateFiltersInput);
    setFilter(true);
  };

  return (
    <div className="row bg-white rounded d-flex  justify-content-center h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">TV Kanallar</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label={showFilter ? "Filtri gizlət" : "Filtri göstər"}
          className="ml-auto mb-0"
        />
      </div>
      <RenderIf condition={isLoading}>
        <div>
          <CircularProgress />
        </div>
      </RenderIf>
      <RenderIf condition={data && !isLoading}>
        <DataTables
          setOpenEdit={setShowEdit}
          setOpenView={toggleDrawer(true)}
          setOpenRemove={setShowRemove}
          setOpenCreate={setShowCreate}
          rows={data?.data}
          count={data?.count}
          rowsPerPage={rowsPerPage}
          onUpdateUserStatus={onUpdateUserStatus}
          page={page}
          permissions={permissions}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          setProxiesId={setProxiesId}
          setDeleteUsersID={setDeleteUsersIds}
          deleteUsersIds={deleteUsersIds}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterStatusesUpdate={filterStatusesUpdate}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </RenderIf>

      <RenderIf condition={showView}>
        <View open={showView} onClose={toggleDrawer(false)} id={proxiesId} />
      </RenderIf>
      <RenderIf condition={showCreate}>
        <Create
          show={showCreate}
          onHide={setShowCreate}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showEdit}>
        <Edit
          id={proxiesId}
          show={showEdit}
          onHide={setShowEdit}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showRemove}>
        <Delete
          id={proxiesId}
          show={showRemove}
          onHide={setShowRemove}
          filterInputs={filterInputs}
        />
      </RenderIf>

      <Filter
        show={showFilter}
        onHide={handleChange}
        filterInputsUpdate={filterInputsUpdate}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
      />
    </div>
  );
}
