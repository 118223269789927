import React, { useState, useEffect } from "react";
import DataTables from "./components/DashboardTables";
import View from "./modals/View";
import Delete from "./modals/Remove";
import Edit from "./modals/Edit";
import { updateObject } from "../../../Helpers/updateObject"
import CircularProgress from "@material-ui/core/CircularProgress/index";
import { useGetRolesQuery } from "../../../redux/api/Roles/rolesApi";
import Create from "./modals/Create";
import { useSelector } from "react-redux";


export function Roles() {
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = React.useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const { user } = useSelector((state) => state.user);
  const permissions = user?.permissions;


  function handleChange() {
    setShowFilter((key) => !key);
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowView(open);
  };

  function doc_keyUp(e) {
    if (e.ctrlKey && e.key === "x") {
      setShowCreate(true);
    }
  }
  document.addEventListener("keyup", doc_keyUp, false);

  
  const [sortField, setSortField] = useState("lastUpdatedDate");
  const [orderBy, setOrderBy] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [roleId, setRoleId] = useState(null);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterInputs, setFilterInputs] = useState({
    name: "",
    address: "",
    email: "",
    phoneNumber: "",
    responsiblePerson: "",
    regionalCenterId: "",
    regionalCenterName: "",
    cityId: "",
    cityName: "",
  });
  const { data, isLoading, refetch } = useGetRolesQuery();

  const filterInputsUpdate = (e) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });

    setFilterInputs(updateFiltersInput);
    setSkip(0);
    setFilter(true);
  };

  const filterStatusesUpdate = (key, value) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });

    setFilterInputs(updateFiltersInput);
    setSkip(0);
    setFilter(true);
  };


  return (
    <div className="row bg-white rounded d-flex justify-content-center h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">Rollar</h1>
      </div>


      {
  
        isLoading ? <div><CircularProgress /></div>:
      data && !isLoading ? (
        <DataTables
          setSortField={setSortField}
          setOrderByTable={setOrderBy}
          sortField={sortField}
          orderBy={orderBy}
          setOpenEdit={setShowEdit}
          setOpenView={toggleDrawer(true)}
          setOpenRemove={setShowRemove}
          setOpenCreate={setShowCreate}
          rows={data}
          updateSkip={setSkip}
          updateTake={setTake}
          permissions={permissions}
          setRoleId={setRoleId}
          setDeleteUsersID={setDeleteUsersIds}
          deleteUsersIds={deleteUsersIds}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterStatusesUpdate={filterStatusesUpdate}
        />
      ):""}
      {showCreate && (
        <Create
          show={showCreate}
          onHide={setShowCreate}
          refetch={refetch}
          skip={skip}
          take={take}
          sortField={sortField}
          orderBy={orderBy}
          filterInputs={filterInputs}
        />
      )}
      {showView && (
        <View id={roleId} open={showView} onClose={toggleDrawer(false)} />
      )}
   { showRemove &&  <Delete
        id={roleId}
        show={showRemove}
        onHide={setShowRemove}
        refetch={refetch}
        skip={skip}
        take={take}
        sortField={sortField}
        orderBy={orderBy}
        filterInputs={filterInputs}
   
      />}

      {showEdit && (
        <Edit
          id={roleId}
          show={showEdit}
          onHide={setShowEdit}
          refetch={refetch}
          filterInputs={filterInputs}
        
        />
      )}
    </div>
  );
}
