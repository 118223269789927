import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactSelect from "../../UI/ReactSelect";
import Preview from "../components/Preview.js";
import InputLabel from "@material-ui/core/InputLabel";
import Button2 from "@material-ui/core/Button";
import { useCreateVideoNewsMutation } from "redux/api/VideoNews/VideoNewsApi";
import { useGetTvChannelsQuery } from "redux/api/TvChannels/TvChannelsApi";
import RenderIf from "app/modules/UI/RenderIf";

export default function Create(props) {
  const { show, onHide, refetch } = props;

  const [preview, setPreview] = useState(false);
  const [file, setFile] = useState("");
  const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [defaultState, setDefaultState] = useState(false);
  const [tvChannelOption, setTvChannelOption] = React.useState(null);
  const [tvChannelOptions, settvChannelOptions] = React.useState(null);

  const initialValues = {
    videoUrl: "",
    title: "",
    newsDate: "",
    tvChannelId: "",
    // file: "",
  };

  const { data } = useGetTvChannelsQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });

  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(e.target.files[0]);

    reader.onloadend = () => {
      // console.log(reader.result);
    };

    reader.readAsDataURL(file);

    setPreview(true);
  };
  const onClick = (e) => {
    setPreview(false);
    setFile("");
  };

  useEffect(() => {
    if (data) {
      const newSelectData = data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      settvChannelOptions(newSelectData);
    }
  }, [data]);

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    createVideoNews,
    { isLoading, error, isSuccess },
  ] = useCreateVideoNewsMutation();
  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    title: Yup.string().required("Başlıq daxil etməyiniz tələb olunur."),
    newsDate: Yup.string().required("Tarix daxil etməyiniz tələb olunur."),
    videoUrl: Yup.string().required("Video Url daxil etməyiniz tələb olunur."),
    // file: Yup.string().required("Subtitle File daxil etməyiniz tələb olunur."),
    tvChannelId: Yup.number()
      .nullable(true)
      .required("Tv Kanal daxil etməyiniz tələb olunur."),
  });

  function handleChangeRole(value) {
    setTvChannelOption(value);
    setFieldValue("tvChannelId", value?.value);
  }

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPost = (values) => {
    const formData = new FormData();
    formData.append("VideoUrl", values.videoUrl);
    formData.append("Title", values.title);
    formData.append("NewsDate", values.newsDate);
    formData.append("TvChannelId", values.tvChannelId);
    formData.append("SubtitleFile", file);

    createVideoNews(formData);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPost(values, resetForm, setSubmitting);
    },
  });

  const { resetForm, values, setFieldValue } = formik;

  useEffect(() => {
    setIsError("");
  }, [show]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Əlavə et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="videoUrl"
                    name="videoUrl"
                    label="Video Url"
                    placeholder="Video Url"
                    className="w-100"
                    error={getInputClasses("videoUrl")}
                    {...formik.getFieldProps("videoUrl")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.videoUrl && formik.errors.videoUrl}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.videoUrl}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="Başlıq"
                    placeholder="Başlıq"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.title && formik.errors.title}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <InputLabel htmlFor="expiredDate">Tarix</InputLabel>
                  <TextField
                    id="newsDate"
                    name="newsDate"
                    type="date"
                    className="w-100"
                    error={getInputClasses("newsDate")}
                    {...formik.getFieldProps("newsDate")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.newsDate && formik.errors.newsDate}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.newsDate}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={tvChannelOptions}
                    handleChangeSingle={(value) => handleChangeRole(value)}
                    single={tvChannelOption}
                    label="Tv Kanal"
                    placeholder="Tv Kanal"
                    inputId="tvChannelId"
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.tvChannelId && formik.errors.tvChannelId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.tvChannelId}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3">
                  <InputLabel htmlFor="expiredDate">File</InputLabel>
                  <label htmlFor="contained-button-file">
                    <input
                      accept=".srt"
                      id="contained-button-file"
                      type="file"
                      name="file"
                      label="File {Path}"
                      placeholder="File Path"
                      className="w-100"
                      onChange={imagePreview}
                      hidden
                    />
                    <Button2 variant="contained" component="span">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}
                        className="mr-2"
                      />
                      Fayl Yüklə
                    </Button2>
                  </label>
                  <Preview
                    preview={preview}
                    fileName={file?.name}
                    onClick={onClick}
                  />
                </div>
             
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Əlavə et
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
