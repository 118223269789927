import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TableCheck from "../components/checkTable";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGetSourcesItemPathCheckQuery } from "redux/api/NewsSourcesPath/newsSourcesPathApi";
import RenderIf from "app/modules/UI/RenderIf";

export default function Create(props) {
  const { show, onHide, refetch, id } = props;
  const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);

  const { data, isLoading, error } = useGetSourcesItemPathCheckQuery(id);

  useEffect(() => {
    setIsError("");
  }, [show]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form>
        <Modal.Header closeButton className="text-center">
          <Modal.Title id="create"> </Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          {isLoading ? (
            <>
              <div className="d-flex align-items-center justify-content-center flex-column h-100 check_info_spinner">
                <CircularProgress />

                <h1 className="load_data_info">Məlumatlar yüklənir...</h1>
              </div>
            </>
          ) : (
            <>
              <RenderIf
                renderElse={
                  <>
                    {" "}
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <h3>
                        <strong> {data?.news?.title}</strong>
                      </h3>
                      <h3>{data?.news?.newsDate?.split("T")[0]}</h3>
                      <p>{data?.news?.article}</p>
                    </div>
                    <h3>
                        <strong> Tapılan xəbər sayı :{data?.newsLists?.length} </strong>
                      </h3>
                    <TableCheck rows={data?.newsLists} />
                  </>
                }
                condition={!data?.newsLists.length && !isLoading}
              >
                <AlertComponent
                  variant="info"
                  message="Heç bir məlumat tapılmadı"
                />
              </RenderIf>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
            }}
          >
            Bağla
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
