import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers/RouterHelpers";
import { BiUser } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { AiOutlinePlaySquare } from "react-icons/ai";
import { AiOutlineDesktop } from "react-icons/ai";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { TbBuildingWarehouse } from "react-icons/tb";
import { BsBuilding } from "react-icons/bs";
import { BiBuilding } from "react-icons/bi";
import { TfiWrite } from "react-icons/tfi";
import { FaSistrix } from "react-icons/fa";
import { useSelector } from "react-redux";

export function AsideMenuList2({ layoutProps }) {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const permissions = user?.permissions;

  const [url, setUrl] = React.useState("/");
  React.useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item  ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <BiUser />
            </span>
            <span className="menu-text">İstifadəçilər</span>
          </NavLink>
        </li>

        {permissions?.Role_View ? (
          <li
            className={`menu-item  ${getMenuItemActive("/roles", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/roles">
              <span className="svg-icon menu-icon">
                <AiOutlineSecurityScan />
              </span>
              <span className="menu-text">Rollar</span>
            </NavLink>
          </li>
        ) : (
          " "
        )}
        <li
          className={`
         ${url.includes("/client") ? " menu-item-active" : ""} menu-item`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/clients">
            <span className="svg-icon menu-icon">
              <AiOutlineSecurityScan />
            </span>
            <span className="menu-text">Müştərilər</span>
          </NavLink>
        </li>

        <li
          className={`menu-item  ${getMenuItemActive(
            "/search-keyword",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/search-keyword">
            <span className="svg-icon menu-icon">
              <AiOutlineSecurityScan />
            </span>
            <span className="menu-text">Axtarış Açar Sözləri</span>
          </NavLink>
        </li>

        <li
          className={`
         ${
           url.includes("/news-sources" || "/scrape") ? " menu-item-active" : ""
         } menu-item`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/news-sources">
            <span className="svg-icon menu-icon">
              <FaSistrix />
            </span>
            <span className="menu-text">Xəbər Mənbələri</span>
          </NavLink>
        </li>

        <li
          className={`menu-item  ${getMenuItemActive("/news", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/news">
            <span className="svg-icon menu-icon">
              <TfiWrite />
            </span>
            <span className="menu-text">Xəbərlər</span>
          </NavLink>
        </li>

        <li
          className={`menu-item  ${getMenuItemActive("/tv-channels", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/tv-channels">
            <span className="svg-icon menu-icon">
            <AiOutlineDesktop />
            </span>
            <span className="menu-text">Tv Kanallar</span>
          </NavLink>
        </li>
        <li
          className={`menu-item  ${getMenuItemActive("/video-news", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/video-news">
            <span className="svg-icon menu-icon">
            <AiOutlinePlaySquare />
            </span>
            <span className="menu-text">Video Xəbərlər</span>
          </NavLink>
        </li>

        <li
          className={`menu-item  ${getMenuItemActive("/categories", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/categories">
            <span className="svg-icon menu-icon">
              <BsBuilding />
            </span>
            <span className="menu-text">Kateqoriyalar</span>
          </NavLink>
        </li>
        <li
          className={`menu-item  ${getMenuItemActive("/user-agents", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-agents">
            <span className="svg-icon menu-icon">
              <TbBuildingWarehouse />
            </span>
            <span className="menu-text">İstifadəçi agentləri</span>
          </NavLink>
        </li>
        <li
          className={`menu-item  ${getMenuItemActive("/proxies", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/proxies">
            <span className="svg-icon menu-icon">
              <TbBuildingWarehouse />
            </span>
            <span className="menu-text">Proksilər</span>
          </NavLink>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
