import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const searchKeywordApi = createApi({
  reducerPath: "searchKeywordApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ['SearchKeyword'],
  endpoints: (builder) => ({
    getCities: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `search-keywords?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.expression ? "&Expression=" + name?.expression : ""
          }${name?.description ? "&Description=" + name?.description : ""}${
            name?.FromDate ? "&FromDate=" + name?.FromDate : ""
          }${
            name?.ToDate ? "&ToDate=" + name?.ToDate : ""
          }`,
         
        };
      },
      providesTags: ['SearchKeyword'],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getKeyword: builder.query({
      query(id) {
        return {
          url: `search-keywords/${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createKeyword: builder.mutation({
      query(form) {
        return {
          url: "search-keywords",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ['SearchKeyword'],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateKeyword: builder.mutation({
      query(data) {
        return {
          url: "search-keywords",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ['SearchKeyword'],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteKeyword: builder.mutation({
      query(id) {
        return {
          url: `search-keywords/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ['SearchKeyword'],
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useGetKeywordQuery,
 useUpdateKeywordMutation,
  useCreateKeywordMutation,
  useDeleteKeywordMutation,
} = searchKeywordApi;
