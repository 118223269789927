import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "@material-ui/core/Input";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Preview from "../components/Preview.js";
import InputLabel from "@material-ui/core/InputLabel";
import Button2 from "@material-ui/core/Button";
import ReactSelect from "../../UI/ReactSelect";
import RenderIf from "app/modules/UI/RenderIf";
import { useGetTvChannelsQuery } from "redux/api/TvChannels/TvChannelsApi.js";
import {
  useGetVideoNewsItemQuery,
  useUpdateVideoNewsMutation,
} from "redux/api/VideoNews/VideoNewsApi.js";

export default function Create(props) {
  const { show, onHide, id } = props;
  const [preview, setPreview] = useState(false);
  const [file, setFile] = useState("");
  const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [defaultState, setDefaultState] = useState(false);
  const [videoNewsOption, setvideoNewsOption] = React.useState(null);
  const [videoNewsOptions, setvideoNewsOptions] = React.useState(null);

  const initialValues = {
    videoUrl: "",
    title: "",
    newsDate: "",
    tvChannelId: "",
    // file: "",
  };

  const { data: TvChannelsData } = useGetTvChannelsQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });

  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(e.target.files[0]);

    reader.onloadend = () => {
      // console.log(reader.result);
    };

    reader.readAsDataURL(file);

    setPreview(true);
  };
  const onClick = (e) => {
    setPreview(false);
    setFile("");
  };

  const { data } = useGetVideoNewsItemQuery(id);

  useEffect(() => {
    if (data) {
      const { id, videoUrl, title, newsDate, subtitle } = data;
      setValues({
        id,
        videoUrl,
        title,
        newsDate: newsDate?.split("T")[0],
        subtitle,
      });
    }
  }, [data]);

  useEffect(() => {
    if (TvChannelsData) {
      const newSelectData = TvChannelsData?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setvideoNewsOptions(newSelectData);
    }
  }, [TvChannelsData]);

  useEffect(() => {
    const videoNewsOptionValue = videoNewsOptions?.find(
      (option) => option.value == data?.tvChannelId
    );
    setvideoNewsOption(videoNewsOptionValue);
    setFieldValue("tvChannelId", videoNewsOptionValue?.value);
  }, [data, videoNewsOptions]);

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateVideoNews,
    { isLoading, isSuccess },
  ] = useUpdateVideoNewsMutation();

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    title: Yup.string().required("Başlıq daxil etməyiniz tələb olunur."),
    newsDate: Yup.string().required("Tarix daxil etməyiniz tələb olunur."),
    videoUrl: Yup.string().required("Video Url daxil etməyiniz tələb olunur."),
    tvChannelId: Yup.number()
      .nullable(true)
      .required("Tv Kanal daxil etməyiniz tələb olunur."),
  });

  function handleChangeRole(value) {
    setvideoNewsOption(value);
    setFieldValue("tvChannelId", value?.value);
  }

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }
    return false;
  };

  const onPostHumanResource = (values) => {
    const formData = new FormData();
    formData.append("Id", values.id);

    formData.append("VideoUrl", values.videoUrl);
    formData.append("Title", values.title);
    formData.append("NewsDate", values.newsDate);
    formData.append("TvChannelId", values.tvChannelId);
    formData.append("SubtitleFile", file);
    updateVideoNews(formData);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { resetForm, values, setFieldValue, setValues, errors } = formik;
  useEffect(() => {
    setIsError("");
  }, [show]);
  const HtmlContent = ({ content }) => {
    const formattedContent = content?.replace(/\r\n/g, "<br />");
    return <div dangerouslySetInnerHTML={{ __html: formattedContent }} />;
  };
  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Düzəliş et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="videoUrl"
                    name="videoUrl"
                    label="Video Url"
                    placeholder="Video Url"
                    className="w-100"
                    error={getInputClasses("videoUrl")}
                    {...formik.getFieldProps("videoUrl")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.videoUrl && formik.errors.videoUrl}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.videoUrl}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="Başlıq"
                    placeholder="Başlıq"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.title && formik.errors.title}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <InputLabel htmlFor="expiredDate">Tarix</InputLabel>
                  <TextField
                    id="newsDate"
                    name="newsDate"
                    type="date"
                    className="w-100"
                    error={getInputClasses("newsDate")}
                    {...formik.getFieldProps("newsDate")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.newsDate && formik.errors.newsDate}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.newsDate}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={videoNewsOptions}
                    handleChangeSingle={(value) => handleChangeRole(value)}
                    single={videoNewsOption}
                    label="Tv Kanal"
                    placeholder="Tv Kanal"
                    inputId="parentCategoryId"
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.parentCategoryId &&
                    formik.errors.parentCategoryId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.parentCategoryId}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3">
                  <InputLabel htmlFor="expiredDate">File</InputLabel>
                  <label htmlFor="contained-button-file">
                    <input
                      accept=".srt"
                      id="contained-button-file"
                      type="file"
                      name="file"
                      label="File {Path}"
                      placeholder="File Path"
                      className="w-100"
                      onChange={imagePreview}
                      hidden
                    />
                    <Button2 variant="contained" component="span">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}
                        className="mr-2"
                      />
                      Fayl Yüklə
                    </Button2>
                  </label>
                  <Preview
                    preview={preview}
                    fileName={file?.name}
                    onClick={onClick}
                  />
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12">
                <InputLabel htmlFor="expiredDate" className="mb-4">
                  SubTitle
                </InputLabel>
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <HtmlContent content={values.subtitle} />
                </div>
                <RenderIf
                  condition={formik.touched.videoUrl && formik.errors.videoUrl}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.videoUrl}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Bağla
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Düzəliş et
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
