import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ['News'],
  endpoints: (builder) => ({
    getSources: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `news?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.title ? "&Title=" + name?.title : ""
          }${name?.body ? "&Body=" + name?.body : ""}${
            name?.deleted === true
              ? `&Deleted=${name?.deleted}`
              : name?.deleted === false
              ? `&Deleted=${name?.deleted}`
              : ""
          }${
            name?.FromDate ? "&FromDate=" + name?.FromDate : ""
          }${
            name?.ToDate ? "&ToDate=" + name?.ToDate : ""
          }`,
         
        };
      },
      providesTags: ['News'],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getNewsItem: builder.query({
      query(id) {
        return {
          url: `news/${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createNews: builder.mutation({
      query(form) {
        return {
          url: "news",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ['News'],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateNews: builder.mutation({
      query(data) {
        return {
          url: "news",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ['News'],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteNews: builder.mutation({
      query(data) {
        return {
          url: `news/${data?.id}`,
          method: "DELETE",
          data: data,
        };
      },
      invalidatesTags: ['News'],
    }),
  }),
});

export const {
useGetSourcesQuery,
useGetNewsItemQuery,
 useUpdateNewsMutation,
 useCreateNewsMutation,
  useDeleteNewsMutation,
} = newsApi;
