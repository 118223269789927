import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import {Button } from "react-bootstrap";
import ReactSelect from "../../UI/ReactSelect";
import DeleteIcon from "@material-ui/icons/Delete";
import PlusIcon from "@material-ui/icons/Add";


const ParticipantInformation = ({
  getInputCheck,
  getFieldProps,
  values,
  touched,
  categoryOptions,
  singleValue,
  errors,
  addProtocolParticipants,
  handleRemoveParticipantItem,
  handleChangeCategory,
}) => {
  return (
    <div>
      {values?.clientCategories?.map((participant, index,key) => {
        const clientCategoriesError =
          (errors.clientCategories?.length &&
            errors.clientCategories[index]) ||
          {};
        const clientCategoriesTouch =
          (touched.clientCategories?.length &&
            touched.clientCategories[index]) ||
          {};

        return (
          <div className="row mt-2">
            <div className="col col-8">
              {" "}
              <ReactSelect
                    suggestions={categoryOptions}
                    handleChangeSingle={(value) => handleChangeCategory(value,index)}
                    single={
                      Array.isArray(singleValue) && singleValue.length > index
                        ? singleValue[index]
                        : undefined
                    }
    
                    label="Kategoriya"
                    placeholder="Kategoriya"
                  
                  />

                  
            {
            clientCategoriesTouch.categoryId && clientCategoriesError.categoryId? (
              <div className="mt-2">
                <div className="text-danger">
                  {clientCategoriesError["categoryId"]
                    ? clientCategoriesError["categoryId"]
                    : clientCategoriesError["categoryId"]}
                </div>
              </div>
            ) : null}
            </div>
            <div className="col col-3">
              {" "}
              <TextField
               name="name"
               label="Sıra"
               placeholder="Sıra"
               className="w-100"
               type="number"
                {...getFieldProps(
                  `clientCategories[${index}].order`
                )}
              />
              {
            clientCategoriesTouch.order && clientCategoriesError.order
             ? (
              <div className="mt-2">
                <div className="text-danger">
                  {clientCategoriesError["order"]
                    ? clientCategoriesError["order"]
                    : clientCategoriesError["order"]}
                </div>
              </div>
            ) : null}
            </div>
           
            <div className="col col-1">
              <span
                aria-label="Delete"
                className="btn mt-5 btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                onClick={() => {
                  handleRemoveParticipantItem(index);
                }}
              >
                <DeleteIcon />
              </span>
            </div>

          </div>
        );
      })}

      <div className="col col-6 d-flex align-items-end mt-4 p-0">
        <Button onClick={addProtocolParticipants} className={`px-3`}>
        <PlusIcon />  Kategoriya və sıra əlavə et
        </Button>
      </div>
    </div>
  );
};

export default ParticipantInformation;
